<template>
    <div class="report-box">
        <div class="left-nav" :class="{hide:isHide}">
            <div class="scroll-box">
                <div class="nav">
                    <div class="li" :class="{selected:navIndex==index}"  v-for="(item,index) in reportNavData" :key="item">
                        <div class="title verticalCenter" @click="navClick(index)">
                            <i class="iconfont" :class="item.icon"></i>
                            {{item.title}}
                        </div>
                        <el-collapse-transition>
                            <div class="children-nav" v-show="navIndex==index">
                                <div class="li" 
                                v-for="childre in item?.childre" :key="childre"
                                :class="{selected:navChildreIndex==childre.key}" 
                                @click="addFrame(childre)"
                                >
                                <span class="nowrap2">{{childre.title}}</span>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
            <div class="more-bnt" @click="isHide=!isHide">
                <i class="iconfont icon-arrow-right" v-if="isHide"></i>
                <i class="iconfont icon-arrow-left" v-else></i>
            </div>
        </div>
        <div class="right-frame-box">
            <tabs class="frame-tabs" v-model="frameIndex">
                <div class="frame-nav">
                    <div class="scroll-box" v-mouse-scroll>
                        <tab-nav class="li" v-for="(item,index) in tabsFrameData" :key="item">
                            {{item.navTitle}}<i class="ico-close iconfont" @click.stop="delFrame(index)">×</i>
                        </tab-nav> 
                    </div>
                </div>
                <div class="frame-pane">
                    <tab-pane v-for="(item,index) in tabsFrameData" :key="item">
                        <div class="component-box" v-if="!item.isHttp">
                            <KeepAlive v-if="item.frameUrl">
                                <component :key="item.componentKey" 
                                    v-on:reload="item.componentKey=!item.componentKey" 
                                    :is="item.frameUrl" 
                                    v-on:addFrame="addFrame"
                                    @skipUrl="(data)=>{skipUrl(item,data)}"
                                    @skipBack="skipBack(item)"
                                    :data="item.data" 
                                    v-on:close="delFrame(index)"></component>
                            </KeepAlive>
                            <el-empty v-else description="加急开发中,请耐心等待"></el-empty>
                        </div>
                        
                        <iframe v-if="item.isHttp" :src="item.frameUrl"></iframe>
                    </tab-pane>
                </div>
            </tabs>
            
        </div>
    </div>
</template>

<script>
import reportModel from './reportModel'

export default {
    components:reportModel.components,
    name:"report",
    data(){
        return {
            /**左边导航是否隐藏 */
            isHide:false,
            navIndex:null,
            navChildreIndex:null,
            reportNavData:[],
            /**报表一级菜单数据 */
            reportNavItems:[
                {key:'Report-ShiftAccount',title:"交班对账类报表",icon:'icon-jiaoban',},
                {key:'Report-Check',title:"稽核类报表",icon:'icon-jihe'},
                {key:'Report-Business', title:"营业分析类报表",icon:'icon-yingyee'},
                {key:'Report-Cost',title:"成本分析类报表",icon:'icon-fenxi'},
                {key:'Report-Perf',title:"绩效提成类报表",icon:'icon-jixiao'}
            ],
            /**报表二级菜单数据 */
            reportNavSubItems:[
                {key:'ReportShiftChanges',title:"交班表",url:"shiftHandover"},
                {key:'ReportDayBusiness',title:"营业日报",url:"businessDailyReport"},
                {key:'ReportBillCheck',title:"单据稽核表",url:"reportBillCheck"},
                {key:'ReportPay',title:'付款明细表',url:"paymentDetailsReport"},
                {key:'ReportSale',title:"菜品销售统计表",url:"billSaleReport"},
                {key:'ReportOperation',title:'异常操作统计表',url:'reportOperation'},
                {key:'ReportLog',title:'操作日志',url:'ReportLog'},
                {key:'ReportPay',title:"付款收入统计表",url:""},
                {key:'ReportPreInfo',title:"预订排菜报表",url:"ReportPreInfo"},
                {key:'ReportBillCost',title:"每日账单毛利率表",url:"reportBillCost"},
                {key:'ReportOrderPerf',title:"点单员业绩统计表",url:"reportOrderPerf"},
                {key:'ReportWaiterNamePerf',title:"服务员业绩统计表",url:"reportWaiterNamePerf"},
                {key:'ReportKitNamePerf',title:"厨师业绩统计表",url:"reportKitNamePerf"},
                {key:'ReportEatservetime',title:"菜品实时状态监控表",url:"reportEatservetime"},
                {key:'ReportEatserve',title:"上菜顺序速度异常监控表",url:"reportEatserve"},
                {key:'ReportEatservetarget',title:"上菜顺序速度指标分析表",url:"reportEatServeTarget"},
                {key:'ReportSaleCommission',title:"销售员业绩报表",url:"reportSaleCommission"},
                {key:'ReportTKReceInfo',title:"券核销统计表",url:"reportTKReceInfo"},
                {key:'ReportEatDesktime',title:"桌号计时统计表",url:"reportEatDesktime"},
            ],
            tabsFrameData:[],
            frameIndex:0
        }
    },
    mounted(){
        this.$emit("navIndex",3);
        this.$nextTick(()=>{
            /**报表菜单数据 */
            this.$cacheData.SysProjects?.get()?.find(t=>t.Project_Key=="Report")
            ?.SysProjectGroups.find(t=>t.Group_Key=='Report_CloudPos' && t.SubGroup_YN)
            ?.SysProjectItems?.forEach(item => {
                if(item.Is_Show){
                    let nav=this.reportNavItems.find(t=>t.key==item.ProjectItem_Key);//一级菜单
                    if(nav){
                        let children=[];
                        item.SysProjectSubItems.forEach((subItem)=>{//二级菜单
                            if(subItem.Is_Show){
                                this.reportNavSubItems.find((childreNav)=>{
                                    if(childreNav.key==subItem.ProjectSubItem_Key){
                                        let data=Object.assign({},childreNav,{title:subItem.ProjectSubItem_Name});
                                        children.push(data);
                                        return true;
                                    }
                                    return false;
                                })
                            }
                        })
                        this.reportNavData.push(Object.assign({},nav,{title:item.ProjectItem_Name,childre:children}));
                    }
                }
            });
        });
    },
    watch:{
        frameIndex(newValue){
            this.navChildreIndex=this.tabsFrameData[newValue]?.key;
        }
    },
    methods:{
        navClick(index){
            if(this.navIndex!=index){
                this.navIndex=index;
            }else{
                this.navIndex=null;
            }
        },
        delFrame(index){
            this.tabsFrameData?.splice(index,1);
            if(index==this.frameIndex){
                if(this.frameIndex>0){
                    this.frameIndex--;
                }
                this.navChildreIndex=this.tabsFrameData[this.frameIndex]?.key;
            }
        },
        /**
         * 穿透 本页签显示 
         * @param item 当前页签数据
         * @param {url 穿透地址,title 页签标题名称,data 穿透传参数据}
         */
        skipUrl(item,{url,title,data}){
            if(!item.skipData){//穿透数据记录
                item.skipData=[{url:item.frameUrl,title:item.navTitle,data:item.data}];
            }
            item.skipData.push({
                url:url,
                title:title,
                data:data
            })
            item.frameUrl=reportModel.getComponent(url);
            item.navTitle=title;
            item.data=data;
        },
        /**
         * 穿透 返回
         */
        skipBack(item){
            if(item.skipData?.length>0){//穿透数据记录
                item.skipData.pop();
                let data= item.skipData[item.skipData.length-1];
                item.frameUrl=reportModel.getComponent(data.url);
                item.navTitle=data.title;
                item.data=data.data;
            }
        },
        addFrame(data){
            this.navChildreIndex=data.key;
            let index= this.tabsFrameData?.findIndex((item)=>item.key==data.key);
            if(index>=0){
                this.frameIndex=index;
                let item=this.tabsFrameData[index];
                item.navTitle=data.title;
                if(/^http/g.test(data.url)){
                    item.frameUrl=data.url;
                    item.isHttp=true;
                }else{
                    item.frameUrl=reportModel.getComponent(data.url);
                    item.data=data.data;
                }
            }else{
                if(this.tabsFrameData.length>=10){
                    this.$message.warning('页签最多开10个,请先清除不用的在打开');
                    return;
                }
                let item={key:data.key,navTitle:data.title,frameUrl:'',isHttp:false,data:data.data};
                if(/^http/g.test(data.url)){
                    item.frameUrl=data.url;
                    item.isHttp=true;
                }else{
                    item.frameUrl=reportModel.getComponent(data.url);
                }
                this.tabsFrameData.push(item);
                this.frameIndex=this.tabsFrameData.length-1;
            }
        }
    }
}
</script>

<style lang='scss'>
@import "./report.scss";
</style>